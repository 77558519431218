import React, { useState, useContext, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Button,
  Card,
  CircularProgress,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import MicrositeDetailsWizardStepper from "./MicrositeDetailsWizardStepper";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CompanyBasicSettingsForm from "../Company/CompanyBasicSettingsForm";
import GlobalContext from "../../lib/GlobalContext";
import Joi from "@hapi/joi";
import FormValueErrorsBuilder from "../../lib/FormValueErrorsBuilder";
import GooglePlacesAPIHelper from "../../lib/GooglePlacesAPIHelper";
import { useMutation } from "urql";
import { MESSAGE_TYPES } from "../PopupMessages";
import { navigate } from "hookrouter";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import LoadingOverlay from "../LoadingOverlay";

/**
 * useStyles
 */
const useStyles = makeStyles((theme) => ({
  intro: {
    margin: 20,
    fontSize: 16,
    lineHeight: "24px",
    textAlign: "left",
    border: "none",
    boxShadow: "none",
    backgroundColor: "#e0f6fa",
  },
  introItem: {
    padding: 10,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  introContainer: {
    flexWrap: "nowrap",
  },
  item: {
    position: "relative",
    width: "100%",
    maxWidth: 500,
    margin: "0 auto",
  },
  itemStepper: {
    maxWidth: 600,
  },
  itemHeader: {
    maxWidth: 444,
    paddingTop: 44,
    paddingBottom: 24,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      paddingBottom: 20,
      textAlign: "left",
    },
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: "30px",
  },
  buttonNext: {
    width: "100%",
    color: "#fff",
    marginRight: "auto",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
  },
  gridItem: {
    width: "100%",
    padding: "8px 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  itemPhone: {
    height: 80,
    overflow: "hidden",
    "&.state-phoneField-open": {
      height: "auto",
      overflow: "visible",
      zIndex: 3,
    },
  },
  itemFax: {
    height: 80,
    overflow: "hidden",
    "&.state-faxField-open": {
      height: "auto",
      overflow: "visible",
      zIndex: 3,
    },
  },
  input: {
    marginLeft: 60,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const updateCompanyMutation = `
  mutation UpdateCompany(
    $hashId: String!,
    $name: String!,
    $contactPerson: String!,
    $street: String!,
    $zip: String!,
    $city: String!,
    $lat: Float,
    $lon: Float,
    $phone: String!,
    $fax: String!,
    $website: String!,
    $email: String!
  ) {
    updateCompany(
      HashID: $hashId, 
      Name: $name,
      ContactPerson: $contactPerson,
      Street: $street,
      Zip: $zip,
      City: $city,
      Latitude: $lat,
      Longitude: $lon,
      Phone: $phone,
      Fax: $fax,
      Website: $website,
      Email: $email
    ) {
      Name
      ContactPerson
      Street
      Zip
      City
      Latitude
      Longitude
      Phone
      Fax
      Website
      Email
      NumberOfEmployees
      LegalForm
    }
  }
`;

const initialFormValuesState = {
  id: "",
  name: "",
  contactPerson: "",
  street: "",
  zip: "",
  city: "",
  phone: "",
  fax: "",
  website: "",
  email: "",
};

const initialFormValueErrorsState = {
  id: { hasError: false, message: "" },
  name: { hasError: false, message: "" },
  contactPerson: { hasError: false, message: "" },
  street: { hasError: false, message: "" },
  zip: { hasError: false, message: "" },
  city: { hasError: false, message: "" },
  phone: { hasError: false, message: "" },
  fax: { hasError: false, message: "" },
  website: { hasError: false, message: "" },
  email: { hasError: false, message: "" },
};

const LOADING_OVERLAY_TIMEOUT = 5000;

/**
 * CompanyProfileStep
 *
 * @param {*} param0
 */
const CompanyProfileStep = ({ step, setStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    googleMapsIsLoaded,
    user,
    token,
    setMessage,
    unsetUser,
    unsetToken,
  } = useContext(GlobalContext);
  const [formValues, setFormValues] = useState(initialFormValuesState);
  const [formValueErrors, setFormValueErrors] = useState(
    initialFormValueErrorsState
  );
  const [streetFormValue, setStreetFormValue] = useState("");
  const [zipFormValue, setZipFormValue] = useState("");
  const [cityFormValue, setCityFormValue] = useState("");
  const [loadingOverlayOpen, setLoadingOverlayOpen] = useState(false);
  const [{ fetching: companyIsUpdating }, executeCompanyUpdateMutation] =
    useMutation(updateCompanyMutation);

  /**
   * Joi validation schema
   */
  const schema = Joi.object({
    id: Joi.number(),
    hashID: Joi.string().required(),
    name: Joi.string().required(),
    contactPerson: Joi.string().required(),
    street: Joi.string().trim().required(),
    zip: Joi.string().trim().required(),
    city: Joi.string().trim().required(),
    phone: Joi.string().allow(""),
    website: Joi.string()
      .regex(
        /https?:\/\/(www\.)?[-a-zA-ZöäüÖÄÜ0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/
      )
      .allow(""),
    fax: Joi.string().allow(""),
    email: Joi.string().email({ tlds: false }).allow(""),
  });

  /**
   * useEffect
   */
  useEffect(() => {
    const userData = user.getData();
    setFormValues({
      id: userData.company.hashID,
      name: userData.company.name || initialFormValuesState.name,
      contactPerson:
        userData.company.contactPerson !== ""
          ? userData.company.contactPerson
          : userData.firstName + " " + userData.lastName,
      street: userData.company.street || initialFormValuesState.street,
      zip: userData.company.zip || initialFormValuesState.zip,
      city: userData.company.city || initialFormValuesState.city,
      phone: userData.company.phone || initialFormValuesState.phone,
      fax: userData.company.fax || initialFormValuesState.fax,
      website: userData.company.website || initialFormValuesState.website,
      email: userData.company.email || initialFormValuesState.email,
    });
  }, [user]);

  /**
   * determineCountry
   *
   * checks if googlePlacesResult is in CH.
   *
   * @param googlePlacesResult
   * @returns {number}
   */
  const determineCountry = (googlePlacesResult) => {
    let isInCH = [];
    googlePlacesResult.results.map((item) => {
      isInCH = item.address_components.filter((addressComponent) => {
        return addressComponent.short_name === "CH";
      });
      return item;
    });
    return isInCH.length;
  };

  /**
   * determineZip
   *
   * @param googlePlacesResult
   * @returns {*}
   */
  const determineZip = (googlePlacesResult) => {
    let zip = formValues.zip;
    googlePlacesResult.results.map((item) => {
      item.address_components.map((comp) => {
        if (comp.types[0] === "postal_code") {
          zip = comp.long_name;
        }
        return zip;
      });
      return zip;
    });
    return zip;
  };

  /**
   * handleButtonNext
   */
  const handleButtonNext = () => {
    if (
      formValues.street.trim() === "" ||
      formValues.street !== streetFormValue
    ) {
      formValues.street = streetFormValue;
    }
    if (formValues.zip !== zipFormValue) {
      formValues.zip = zipFormValue;
    }
    if (formValues.city.trim() === "" || formValues.city !== cityFormValue) {
      formValues.city = cityFormValue;
    }

    if (
      formValues.website !== "" &&
      !formValues.website.includes("http://") &&
      !formValues.website.includes("https://")
    ) {
      formValues.website = "https://" + formValues.website;
    }

    const formData = {
      hashID: user.getData().company.hashID,
      name: formValues.name,
      contactPerson: formValues.contactPerson,
      street: formValues.street,
      zip: formValues.zip,
      city: formValues.city,
      phone: formValues.phone,
      fax: formValues.fax,
      website: formValues.website,
      email: formValues.email,
    };
    const { error } = schema.validate(formData, { abortEarly: false });
    if (error) {
      console.log(error);
      const formErrors = FormValueErrorsBuilder(error, t);
      setFormValueErrors({ ...formErrors });
      window.scrollTo(0, 0);
    } else {
      user.setData({ company: { ...formData } });
      setFormValueErrors(initialFormValueErrorsState);
      const addressString = `${formValues.street}+${
        typeof formValues.number !== "undefined" ? formValues.number + "+" : ""
      }${formValues.zip}+${formValues.city}`;

      GooglePlacesAPIHelper.getCoordsByAddress(addressString).then((result) => {
        if (result.status === "OK" && determineCountry(result)) {
          const lat = result.results[0].geometry.location.lat;
          const lon = result.results[0].geometry.location.lng;

          user.setData({ company: { latitude: lat, longitude: lon } });

          const showLoadingOverlayTimeout = setTimeout(
            () => setLoadingOverlayOpen(true),
            LOADING_OVERLAY_TIMEOUT
          );

          const foundZip = determineZip(result);

          executeCompanyUpdateMutation({
            hashId: user.getData().company.hashID,
            name: formValues.name,
            contactPerson: formValues.contactPerson,
            street: formValues.street,
            zip: foundZip,
            city: formValues.city,
            lat: lat,
            lon: lon,
            phone: formValues.phone,
            fax: formValues.fax,
            website: formValues.website,
            email: formValues.email,
          }).then((result) => {
            clearTimeout(showLoadingOverlayTimeout);
            setLoadingOverlayOpen(false);

            if (result.error) {
              if (result.error.message.indexOf("User forced logout") !== -1) {
                setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
                unsetUser();
                unsetToken();
                navigate("/");
              } else {
                setMessage(MESSAGE_TYPES.ERROR, t("error.save"));
                ExternalErrorLogger.log({
                  text: "Error submitting data on CompanyBasicSettings",
                  data: {
                    token: JSON.stringify(token.getData()),
                    user: JSON.stringify(user.getData()),
                    errorMessage: result.error.message,
                  },
                });
              }
            } else {
              setStep((prevActiveStep) => prevActiveStep + 1);
            }
          });
        } else if (result.status === "OK" && !determineCountry(result)) {
          setFormValueErrors({
            street: {
              hasError: true,
              message: t("error.form.address.not.ch"),
            },
            zip: {
              hasError: true,
              message: t("error.form.address.not.ch"),
            },
            city: {
              hasError: true,
              message: t("error.form.address.not.ch"),
            },
          });
          window.scrollTo(0, 0);
        } else if (result.status === "ZERO_RESULTS") {
          setFormValueErrors({
            street: {
              hasError: true,
              message: t("error.form.invalid.address"),
            },
            zip: {
              hasError: true,
              message: t("error.form.invalid.address"),
            },
            city: {
              hasError: true,
              message: t("error.form.invalid.address"),
            },
          });
          window.scrollTo(0, 0);
        } else {
          let errorMessage = "";
          if (result.error_message !== undefined) {
            errorMessage =
              "<br />" +
              '<span style="font-weight: bold">' +
              t("general.label.error.message") +
              "</span>" +
              " " +
              result.error_message;
          }
          setMessage(
            MESSAGE_TYPES.ERROR,
            t("error.general.short") +
              "<br />" +
              "<br />" +
              '<span style="font-weight: bold">' +
              t("general.label.status.code") +
              "</span>" +
              " " +
              result.status +
              errorMessage +
              "<br />" +
              "<br />" +
              t("general.label.contact.smartconext")
          );
          ExternalErrorLogger.log({
            text: "Error fetching Geocoords on CompanyBasicSettings",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error_message,
            },
          });
        }
      });
    }
  };

  /**
   * return
   */
  return (
    <>
      {googleMapsIsLoaded && (
        <>
          <Grid item className={clsx(classes.item, classes.itemHeader)}>
            <h1 className={classes.title}>
              {t("microsite.details.wizard.step.1.title")}
            </h1>
            <Card variant="outlined" className={classes.intro}>
              <Grid
                container
                className={classes.introContainer}
                alignItems="center"
              >
                <Grid item className={classes.introItem}>
                  <InfoOutlinedIcon className={classes.icon} />
                </Grid>
                <Grid item className={classes.introItem}>
                  {t("microsite.details.wizard.step.1.intro")}
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item className={clsx(classes.item, classes.itemStepper)}>
            <MicrositeDetailsWizardStepper step={step} />
          </Grid>
          <Grid item className={classes.item}>
            <CompanyBasicSettingsForm
              classes={classes}
              formValues={formValues}
              formValueErrors={formValueErrors}
              setFormValues={setFormValues}
              streetFormValue={streetFormValue}
              setStreetFormValue={setStreetFormValue}
              zipFormValue={zipFormValue}
              setZipFormValue={setZipFormValue}
              cityFormValue={cityFormValue}
              setCityFormValue={setCityFormValue}
            />
          </Grid>
          <Grid item className={clsx(classes.item, classes.buttonContainer)}>
            <Button
              id="buttonFormNext"
              variant="contained"
              color="primary"
              align="left"
              className={classes.buttonNext}
              onClick={handleButtonNext}
              disabled={companyIsUpdating}
            >
              {t("form.label.next")}
              {companyIsUpdating && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
            {loadingOverlayOpen && (
              <LoadingOverlay text={t("general.label.be.ready.soon")} />
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default CompanyProfileStep;
