import React, { useContext, useEffect, useState } from 'react'
import GlobalContext from '../../lib/GlobalContext'
import { useTranslation } from 'react-i18next'
import { DialogTitle, makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import FreeToPremiumDialogueStep0 from './FreeToPremiumDialogueStep0'
import FreeToPremiumDialogueStep1 from './FreeToPremiumDialogueStep1'
import FreeToPremiumDialogueStep2 from './FreeToPremiumDialogueStep2'
import FreeToPremiumDialogueStep3 from './FreeToPremiumDialogueStep3'
import FreeToPremiumDialogueStep4 from './FreeToPremiumDialogueStep4'
import FreeToPremiumDialogueStep5 from './FreeToPremiumDialogueStep5'
import withAccessControl from '../HOC/AccessControl'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import { ACL_COMPONENTS } from '../../config'

const initialFormData = {
  step0: {
    plans: {
      monthly: {},
      yearly: {}
    }
  },
  step1: {
    company: '',
    street: '',
    zip: '',
    city: '',
    lat: '',
    lon: '',
    phone: '',
    fax: '',
    website: '',
    email: ''
  },
  step2: {
    branchCategory: 0,
    branchSubs: []
  },
  step3: {
    chosenPlan: 'yearly',
    terms: false
  },
  step4: {
    cardholder: '',
    cardNumber: '',
    cardExpiry: '',
    cardCVC: ''
  }
}

/**
 * useStyles
 */
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  dialog: {
    width: '100%',
    paddingBottom: '56px',
    '&::before': theme.watermarkLogo
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingBottom: 0
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row'
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column'
    }
  }
}))

/**
 * UpgradeToPremium
 *
 * @returns {*}
 * @constructor
 */
const UpgradeToPremium = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { user, setUpgradeToPremiumOpen, setPaymentMethodId } = useContext(
    GlobalContext
  )
  const [step, setStep] = useState(0) // Initial step
  const [openDialog, setOpenDialog] = useState(false)
  const [formData, setFormData] = useState(initialFormData)
  const notifyBeforeClose = props.notifyBeforeClose || false

  useEffect(() => {
    if (user.getData().mode === null) {
      setOpenDialog(true)
    } else {
      setOpenDialog(props.open)
    }
  }, [props.open, user])

  useEffect(() => {
    if (user.getData().mode === 'smart' || user.getData().mode === 'pro') {
      if (
        user.getData().company.stripePlanId === null ||
        user.getData().company.stripePlanId === undefined
      ) {
        setStep(1)
      }
    }
  }, [user])

  /**
   * closeDialogue
   */
  const closeDialogue = () => {
    if (user.getData().mode === null) {
      return
    }
    if (
      user.getData().company.stripePlanId === null &&
      user.getData().company.stripeProductId === null
    ) {
      return
    }
    if (notifyBeforeClose) {
      notifyBeforeClose()
    }
    setFormData(initialFormData)
    setPaymentMethodId(null)
    setUpgradeToPremiumOpen(false)
    setOpenDialog(false)
  }

  /**
   * showSmartTrialNotification
   *
   * @param trialPeriodDays
   * @param trialPossible
   * @returns {boolean}
   */
  const showSmartTrialNotification = (trialPeriodDays, trialPossible) => {
    return !!(trialPossible && trialPeriodDays > 0)
  }

  /**
   * getDialogByStep
   *
   * @returns {*}
   */
  const getDialogByStep = () => {
    switch (step) {
      case 1:
        return (
          <FreeToPremiumDialogueStep1
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
          />
        )
      case 2:
        return (
          <FreeToPremiumDialogueStep2
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
          />
        )
      case 3:
        return (
          <FreeToPremiumDialogueStep3
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            showSmartTrialNotification={showSmartTrialNotification}
          />
        )
      case 4:
        return (
          <FreeToPremiumDialogueStep4
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
          />
        )
      case 5:
        return (
          <FreeToPremiumDialogueStep5
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            closeDialogue={closeDialogue}
          />
        )
      default:
        return (
          <FreeToPremiumDialogueStep0
            step={step}
            setStep={setStep}
            formData={formData}
            setFormData={setFormData}
            showSmartTrialNotification={showSmartTrialNotification}
          />
        )
    }
  }

  /**
   * showDialogCloseButton
   *
   * @returns {boolean}
   */
  const showDialogCloseButton = () => {
    let closeButtonVisible = true
    if (user.getData().mode !== null) {
      closeButtonVisible = true
    }
    if (user.getData().mode === null) {
      closeButtonVisible = false
    }
    if (
      user.getData().company.stripePlanId === null &&
      user.getData().company.stripeProductId === null
    ) {
      closeButtonVisible = false
    }
    return closeButtonVisible
  }

  /**
   * return
   */
  return (
    <Dialog
      className={classes.root}
      fullWidth
      maxWidth='md'
      open={openDialog}
      onClose={closeDialogue}
    >
      <DialogTitle id='dialog-title' className={classes.dialogTitle}>
        {showDialogCloseButton() && (
          <IconButton
            onClick={() => {
              closeDialogue()
            }}
          >
            <CloseIcon id='closeDialog' />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Grid
          container
          direction='column'
          alignItems='center'
          justify='center'
          className={classes.container}
        >
          {user.isSet() && getDialogByStep()}
          {!user.isSet() && (
            <Grid item>
              <div>{t('error.fetching.user')}</div>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default withAccessControl(
  UpgradeToPremium,
  ACL_COMPONENTS.UPGRADE_TO_PREMIUM
)
