import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useTranslation } from "react-i18next";
import withAccessControl, { parsePermissions } from "../HOC/AccessControl";
import { useMutation, useQuery } from "urql";
import { makeStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserTypes from "../Onboarding/UserTypes";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Link from "@material-ui/core/Link";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import GlobalContext from "../../lib/GlobalContext";
import { navigate } from "hookrouter";
import LoadingOverlay from "../LoadingOverlay";
import { MESSAGE_TYPES } from "../PopupMessages";
import { externalLinkTitles, getAbsoluteLink } from "../../lib/CmsLinkHandler";
import { ACL_COMPONENTS, STRIPE_PLAN_IDS } from "../../config";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import FormatHelper from "../../lib/FormatHelper";

/**
 * useStyles
 */
const useStyles = makeStyles((theme) => ({
  planList: {
    paddingTop: 80,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
  card: {
    width: "100%",
    maxWidth: 330,
    marginTop: 20,
    marginBottom: 20,
    "&:hover": {
      borderColor: theme.palette.primary.main,
      cursor: "pointer",
    },
  },
  cardContent: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  gridItemContainer: {
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "nowrap",
    },
  },
  gridItemLeft: {
    textAlign: "center",
    color: theme.palette.primary.main,
    paddingTop: 5,
  },
  gridItemRight: {
    color: "#999999",
    fontSize: 14,
    lineHeight: "24px",
    fontWeight: "bold",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      paddingRight: 10,
      paddingLeft: 10,
    },
  },
  icon: {
    fontSize: 32,
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: "30px",
  },
  link: {
    display: "inline-block",
    width: "100%",
    maxWidth: 330,
    margin: "0 auto",
    paddingBottom: 84,
    textAlign: "left",
  },
  checkbox: {
    color: theme.palette.primary.main,
    "&:checked": {
      color: theme.palette.primary.main,
    },
  },
  pricing: {
    fontSize: 24,
    color: "#525252",
    fontWeight: "bold",
    padding: "10px 0",
  },
  strikePrice: {
    fontSize: 24,
    color: "#525252",
    fontWeight: "bold",
    padding: "10px 0",
    textDecoration: "line-through",
  },
  benefits: {
    fontSize: 14,
    color: "#525252",
    fontWeight: "normal",
  },
  taxHint: {
    fontSize: 12,
    color: "#525252",
    fontWeight: "bold",
    padding: "10px 0",
  },
  trialNotificationBox: {
    marginBottom: 10,
  },
  trialNotification: {
    fontSize: 14,
    fontWeight: "normal",
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main,
    height: 32,
    display: "table-cell",
    verticalAlign: "middle",
    padding: "5px 10px 3px 10px",
    marginBottom: 10,
  },
  trialEndedNotification: {
    fontSize: 14,
    fontWeight: "normal",
    display: "table-cell",
    verticalAlign: "middle",
    padding: "20px 10px 0px 10px",
  },
  container: {
    position: "relative",
  },
  loadingProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -36,
    marginLeft: -12,
  },
}));

const stripeProductsQuery = `
  {
    readProduct {
      id
      description
      metadata {
        FeatureSet
      }
      name
      plans {
        id
        nickname
        amount
        currency
        interval
        interval_count
        active
        trial_period_days
        trial_possible
      }
    }
  }
`;

const updateStripePlanMutation = `
  mutation UpdateCompany (
    $hashId: String!,
    $futurePlanID: String
  ) {
    updateCompany (
      HashID: $hashId, 
      FuturePlanID: $futurePlanID
    ) {
      Name
      FeatureSet
    }
  }
`;

const queryMyself = `
{
  readMyself {
    ID
    HashID
    Locale
    Email
    Firstname
    Surname
    Salutation
    StripePublicKey
    CreditCard {
      id
      name
      last4
      expired
    }
    Locations {
      edges {
        node {
          HashID
          Title
          Street
          Zip
          City
          Longitude
          Latitude
          Radius
          Version
          Sort
          Removed
        }
      }
    }
    Company {
      ID
      AdminUserID
      HashID
      FeatureSet
      ZipSearchAreas
      ZipSearchStatistic
      ApplicationFollowUpTimeInDays
      StripeIsTrialing
      StripeTrialingDays
      StripePlanID
      StripeValidThrough
      StripeProductID
      StripeCancelAt
      Name
      URLSegment
      ContactPerson
      Street
      Zip
      City
      Canton
      Distance
      Latitude
      Longitude
      Phone
      Fax
      Website
      Email
      NumberOfEmployees
      LegalForm
      FoundingYear
      MasterBusiness
      TrainingCompany
      BusinessActivityID
      BusinessSpecialities{
        edges {
          node {
            ID
            Title
            NogaCode
            SCCodeCompetence
            BoostScore
          }
        }
      }
      OtherBusinessActivities {
        edges {
          node {
            ID
          }
        }
      }
      OtherBusinessSpecialities {
        edges {
          node {
            ID
          }
        }
      }
      User{
        edges {
          node {
            HashID
            Email
            Firstname
            Surname
            Removed
            SearchAreas {
              HashID
              Title
              ZipList
            }
          }
        }
      }
      Memberships {
        edges {
          node {
            ID
            Title
            LogoUrl
          }
        }
      }
      AllMemberships {
        edges {
          node {
            ID
            Title
            LogoUrl
            IsBadge
          }
        }
      }
      MicrositeCertified
      MicrositeCertifiedUntil
      AdditionalMemberships
      MicrositeColorScheme
      MicrositeDescription
      MicrositeEmailApplicationText
      MicrositeImages {
        edges {
          node {
            Src
            Title
            Alt
          }
        }
      }
      MicrositeLogo {
        Src
        Title
        Alt
      }
      MicrositeReferences {
          edges {
            node {
              Sort
              Title
              Content
              Location
              Year
              Month
              ButtonLink
              ButtonTitle
              MicrositeReferencesImages {
              edges { 
                node {
                  Src
                  Title
                  Alt
                }
              }
            }
            }
          }
        }
        ApiAccess {
          edges {
            node {
              RequestUrl
            }
          } 
        }  
    }
    DossierTemplates {
      edges {
        node {
          HashID
          TemplateName
        }
      }
    }
    Permissions {
      Name
      RequireAdminStatus
    }
    FulltextSearchRequests {
      ID
      Title
    }
    SearchAreas {
      HashID
      Title
      ZipList
    }
  }
  readProduct {
    plans {
      id
      trial_possible
    }
  }
}
`;

let triggerMyselfHandling = false;

/**
 * FreeToPremiumDialogueStep0
 *
 * @returns {*}
 * @constructor
 */
const FreeToPremiumDialogueStep0 = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    user,
    token,
    setToken,
    setUser,
    setUpgradeToPremiumOpen,
    setMessage,
    unsetUser,
    unsetToken,
    cmsLinks,
  } = useContext(GlobalContext);
  const [stripeProductData, setStripeProductData] = useState([]);
  const [checkboxState, setCheckboxState] = useState({
    smart: false,
    pro: false,
  });

  /**
   * GraphQL query for products
   */
  const [result, executeStripeProductsQuery] = useQuery({
    query: stripeProductsQuery,
    requestPolicy: "network-only",
    pause: true,
  });

  /**
   * GraphQL mutation for updating the company plan
   */
  const [{ fetching }, executeUpdateStripePlanMutation] = useMutation(
    updateStripePlanMutation
  );

  /**
   * GraphQL query for user-data (myself), gets triggered by calling "executeMyselfQuery()"
   */
  const [resultMyself, executeMyselfQuery] = useQuery({
    query: queryMyself,
    variables: { token: token.getData().access_token }, // Required as cache-control mechanism
    requestPolicy: "network-only",
    pause: true,
  });

  /**
   * useEffect
   */
  useEffect(() => {
    if (!stripeProductData.length) {
      executeStripeProductsQuery();
    }
  }, [stripeProductData.length, executeStripeProductsQuery, token, setToken]);

  /**
   * GraphQL trigger and result handling
   */
  useEffect(() => {
    if (!fetching && !result.fetching && !resultMyself.fetching) {
      if (result.error) {
        // Check if the user need to be logged out
        if (result.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // The query did not return any results!
          ExternalErrorLogger.log({
            text: "Error fetching stripe productdata on FreeToPremiumDialogueStep0",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: result.error.message,
            },
          });
        }
      } else {
        if (
          typeof result.data !== "undefined" &&
          typeof result.data.readProduct !== "undefined" &&
          !stripeProductData.length
        ) {
          const orderedStripeProductData = result.data.readProduct;
          // reorder the result so Free is at the end
          const priority = ["smart", "pro", "gratis"];
          orderedStripeProductData.sort((a, b) => {
            return (
              priority.indexOf(a.name.toLowerCase().split(" ").pop()) -
              priority.indexOf(b.name.toLowerCase().split(" ").pop())
            );
          });
          setStripeProductData(orderedStripeProductData);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetching, result, resultMyself])

  /**
   * GraphQL trigger and result handling
   */
  useEffect(() => {
    if (
      !fetching &&
      !result.fetching &&
      !resultMyself.fetching &&
      token.isSet()
    ) {
      if (resultMyself.error) {
        // Check if the user need to be logged out
        if (resultMyself.error.message.indexOf("User forced logout") !== -1) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // The query did not return any results!
          ExternalErrorLogger.log({
            text: "Error fetching myself on FreeToPremiumDialogueStep0",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: resultMyself.error.message,
            },
          });
        }
      } else {
        // Query not fetching right now
        if (
          triggerMyselfHandling &&
          typeof resultMyself.data !== "undefined" &&
          typeof resultMyself.data.readMyself !== "undefined"
        ) {
          triggerMyselfHandling = false;
          const branchSubsData = [];
          resultMyself.data.readMyself[0].Company.BusinessSpecialities.edges.map(
            (item) => {
              branchSubsData.push(parseInt(item.node.ID));
              return branchSubsData;
            }
          );

          const locationData = [];
          resultMyself.data.readMyself[0].Locations.edges.map((item) => {
            const constructionSums = [];
            item.node.ConstructionSumList.edges.map((constructionSum) => {
              constructionSums.push(constructionSum.node.Code);
              return constructionSums;
            });

            item.node.ConstructionSums = constructionSums;

            locationData.push(item.node);
            return locationData;
          });

          locationData.sort((a, b) => {
            return a.Sort - b.Sort;
          });

          const companyUsersData = [];
          resultMyself.data.readMyself[0].Company.User.edges.map((item) => {
            if (item.node.HashID !== resultMyself.data.readMyself[0].HashID) {
              companyUsersData.push(item.node);
            }
            return companyUsersData;
          });

          const memberships = [];
          resultMyself.data.readMyself[0].Company.Memberships.edges.map(
            (item) => {
              memberships.push(item.node);
              return memberships;
            }
          );

          const allMemberships = [];
          resultMyself.data.readMyself[0].Company.AllMemberships.edges.map(
            (item) => {
              allMemberships.push(item.node);
              return allMemberships;
            }
          );

          const dossierTemplatesData = [];
          resultMyself.data.readMyself[0].DossierTemplates.edges.map((item) => {
            dossierTemplatesData.push(item.node);
            return dossierTemplatesData;
          });

          companyUsersData.sort((a, b) => {
            if (a.Firstname.toLowerCase() < b.Firstname.toLowerCase()) {
              return -1;
            }
            if (a.Firstname.toLowerCase() > b.Firstname.toLowerCase()) {
              return 1;
            }
            return 0;
          });

          // Close upgrade process
          setUpgradeToPremiumOpen(false);

          let trialPossible = false;

          result.data.readProduct.map((productItem) => {
            productItem.plans.map((item) => {
              if (item.id.includes(STRIPE_PLAN_IDS.SMART.YEARLY)) {
                trialPossible = item.trial_possible;
              }
              return item;
            });
            return productItem;
          });

          // Map the user-data
          user.setData({
            id: resultMyself.data.readMyself[0].ID,
            hashID: resultMyself.data.readMyself[0].HashID,
            locale: resultMyself.data.readMyself[0].Locale || "",
            email: resultMyself.data.readMyself[0].Email,
            firstName: resultMyself.data.readMyself[0].Firstname,
            lastName: resultMyself.data.readMyself[0].Surname,
            salutation: resultMyself.data.readMyself[0].Salutation || "",
            mode:
              resultMyself.data.readMyself[0].Company.FeatureSet === null
                ? null
                : resultMyself.data.readMyself[0].Company.FeatureSet.toLowerCase(),
            stripePublicKey:
              resultMyself.data.readMyself[0].StripePublicKey || "",
            creditCard: resultMyself.data.readMyself[0].CreditCard,
            locations: locationData || [],
            company: {
              id: resultMyself.data.readMyself[0].Company.ID,
              hashID: resultMyself.data.readMyself[0].Company.HashID,
              name:
                resultMyself.data.readMyself[0].Company.Name ||
                "TODO COMPANY-NAME", // TODO: Remove fallback company name for production (requires consistent data)
              street: resultMyself.data.readMyself[0].Company.Street || "",
              zip: resultMyself.data.readMyself[0].Company.Zip || "",
              city: resultMyself.data.readMyself[0].Company.City || "",
              canton: resultMyself.data.readMyself[0].Company.Canton || "",
              distance: resultMyself.data.readMyself[0].Company.Distance || 50,
              latitude: resultMyself.data.readMyself[0].Company.Latitude || null,
              longitude:
                resultMyself.data.readMyself[0].Company.Longitude || null,
              phone: resultMyself.data.readMyself[0].Company.Phone || "",
              fax: resultMyself.data.readMyself[0].Company.Fax || "",
              email: resultMyself.data.readMyself[0].Company.Email || "",
              website: resultMyself.data.readMyself[0].Company.Website || "",
              employeeCount:
                resultMyself.data.readMyself[0].Company.NumberOfEmployees || "",
              legalForm: resultMyself.data.readMyself[0].Company.LegalForm || "",
              foundingYear:
                resultMyself.data.readMyself[0].Company.FoundingYear || "",
              masterOperation:
                FormatHelper.boolToString(
                  resultMyself.data.readMyself[0].Company.MasterBusiness
                ) || "",
              trainingCompany:
                FormatHelper.boolToString(
                  resultMyself.data.readMyself[0].Company.TrainingCompany
                ) || "",
              branchCategory:
                resultMyself.data.readMyself[0].Company.BusinessActivityID || "",
              branchSubs: branchSubsData || [],
              users: companyUsersData || [],
              stripeIsTrialing:
                resultMyself.data.readMyself[0].Company.StripeIsTrialing,
              stripeTrialingDays:
                resultMyself.data.readMyself[0].Company.StripeTrialingDays,
              stripePlanId: resultMyself.data.readMyself[0].Company.StripePlanID,
              stripeValidThrough:
                resultMyself.data.readMyself[0].Company.StripeValidThrough,
              stripeProductId:
                resultMyself.data.readMyself[0].Company.StripeProductID,
              stripeCancelAt:
                resultMyself.data.readMyself[0].Company.StripeCancelAt,
              stripeFuturePlans:
                resultMyself.data.readMyself[0].Company.StripeFuturePlans,
              trialPossible: trialPossible,
              memberships: memberships,
              allMemberships: allMemberships,
              zipSearchAreas:
                resultMyself.data.readMyself[0].Company.ZipSearchAreas,
              zipSearchStatistic:
                resultMyself.data.readMyself[0].Company.ZipSearchStatistic,
              applicationFollowUpTimeInDays:
                resultMyself.data.readMyself[0].Company.ApplicationFollowUpTimeInDays
            },
            dossierTemplates: dossierTemplatesData,
            permissions: parsePermissions(
              resultMyself.data.readMyself[0].Permissions
            ),
            fulltextSearchRequests:
              resultMyself.data.readMyself[0].FulltextSearchRequests,
            searchAreas: resultMyself.data.readMyself[0].SearchAreas,
          });

          // Add the user to global context
          setUser(user);

          // Redirect
          navigate("/projects-free");
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, resultMyself, fetching, token])

  /**
   * handlePlanChange
   *
   * @param planData
   */
  const handlePlanChange = (planData) => {
    if (planData.name.toLowerCase().includes("gratis")) {
      if (user.getData().mode === UserTypes.FREE) {
        setUpgradeToPremiumOpen(false);
        navigate("/projects-free");
      } else {
        setCheckboxState({
          smart: false,
          pro: false,
        });

        executeUpdateStripePlanMutation({
          hashId: user.getData().company.hashID,
          futurePlanID: planData.plans[0].id,
        }).then((result) => {
          if (result.error) {
            // Check if the user need to be logged out
            if (result.error.message.indexOf("User forced logout") !== -1) {
              setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
              setUpgradeToPremiumOpen(false);
              unsetUser();
              unsetToken();
              navigate("/");
            } else {
              ExternalErrorLogger.log({
                text: "Error changing plan on FreeToPremiumDialogueStep0",
                data: {
                  token: JSON.stringify(token.getData()),
                  user: JSON.stringify(user.getData()),
                  planData: JSON.stringify(planData),
                  errorMessage: result.error.message,
                },
              });
            }
          } else {
            if (
              typeof result.data !== "undefined" &&
              typeof result.data.updateCompany !== "undefined" &&
              result.data.updateCompany !== null
            ) {
              triggerMyselfHandling = true;
              // Update the user with updated company setup
              executeMyselfQuery();
            }
          }
        });
      }
    } else {
      let monthlyPlanData = {};
      let yearlyPlanData = {};
      planData.plans.map((item) => {
        if (item.id.includes("monthly")) {
          monthlyPlanData = item;
        } else if (
          item.id.includes(STRIPE_PLAN_IDS.SMART.YEARLY) ||
          item.id.includes(STRIPE_PLAN_IDS.PRO.YEARLY)
        ) {
          yearlyPlanData = item;
        }
        return item;
      });
      props.setFormData(
        Object.assign({}, props.formData, {
          step0: {
            plans: { monthly: monthlyPlanData, yearly: yearlyPlanData },
          },
          step2: {
            branchSubs: user.getData().company.branchSubs,
          },
        })
      );
      if (user.hasAddress()) {
        props.setStep(3);
      } else {
        props.setStep(1);
      }
    }
  };

  /**
   * FreePlanCard
   *
   * @param data
   * @returns {*}
   * @constructor
   */
  const FreePlanCard = (data) => {
    return (
      <Card
        key={data.id}
        id="freePlanCard"
        className={classes.card}
        variant="outlined"
        onClick={() => handlePlanChange(data)}
      >
        <CardContent className={classes.cardContent}>
          <Grid
            container
            direction="row"
            alignContent="center"
            alignItems="center"
            className={classes.gridItemContainer}
          >
            <Grid item sm={2} className={classes.gridItemLeft}>
              <ChevronLeftIcon className={classes.icon} />
            </Grid>
            <Grid item sm={10} className={classes.gridItemRight}>
              {t("general.label.back.to.free")}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  /**
   * SmartPlanCard
   *
   * @param data
   * @returns {*}
   * @constructor
   */
  const SmartPlanCard = (data) => {
    let yearlyPlanData = null;

    data.plans.map((item) => {
      if (item.id.includes(STRIPE_PLAN_IDS.SMART.YEARLY)) {
        yearlyPlanData = item;
      }
      return item;
    });

    return yearlyPlanData ? (
      <Card
        key={data.id}
        id="smartPlanCard"
        className={classes.card}
        variant="outlined"
        onClick={() => handlePlanChange(data)}
        onMouseEnter={() => {
          setCheckboxState({
            ...checkboxState,
            smart: true,
          });
        }}
        onMouseLeave={() => {
          setCheckboxState({
            ...checkboxState,
            smart: false,
          });
        }}
      >
        <CardContent className={classes.cardContent}>
          <Grid
            container
            direction="row"
            alignContent="center"
            alignItems="center"
            className={classes.gridItemContainer}
          >
            <Grid item sm={2} className={classes.gridItemLeft}>
              <Checkbox
                className={classes.checkbox}
                checked={checkboxState.smart}
                value="smart"
                color="primary"
                inputProps={{
                  "aria-label": "checkbox smart",
                }}
              />
            </Grid>
            <Grid item sm={10} className={classes.gridItemRight}>
              <Grid container direction="column">
                <Grid item>{data.name}</Grid>
                <Grid item>
                  <Typography
                    className={
                      props.showSmartTrialNotification(
                        yearlyPlanData.trial_period_days,
                        yearlyPlanData.trial_possible
                      )
                        ? classes.strikePrice
                        : classes.pricing
                    }
                  >
                    {yearlyPlanData.currency.toUpperCase()}{" "}
                    {(
                      parseInt(yearlyPlanData.amount).toFixed(2) /
                      100 /
                      12
                    ).toFixed(2)}{" "}
                    / {t("general.label.month")}
                  </Typography>
                  {props.showSmartTrialNotification(
                    yearlyPlanData.trial_period_days,
                    yearlyPlanData.trial_possible
                  ) && (
                    <div className={classes.trialNotificationBox}>
                      <Typography className={classes.trialNotification}>
                        {t("general.label.days.of.testing", {
                          days: yearlyPlanData.trial_period_days,
                        })}
                      </Typography>
                    </div>
                  )}
                  <Typography className={classes.benefits}>
                    {t("general.label.smart.benefits")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ) : null;
  };

  /**
   * ProPlanCard
   *
   * @param data
   * @returns {*}
   * @constructor
   */
  const ProPlanCard = (data) => {
    let yearlyPlanData = null;
    data.plans.map((item) => {
      if (item.id.includes(STRIPE_PLAN_IDS.PRO.YEARLY)) {
        yearlyPlanData = item;
      }
      return item;
    });

    return yearlyPlanData ? (
      <Card
        key={data.id}
        id="proPlanCard"
        className={classes.card}
        variant="outlined"
        onClick={() => handlePlanChange(data)}
        onMouseEnter={() => {
          setCheckboxState({ ...checkboxState, pro: true });
        }}
        onMouseLeave={() => {
          setCheckboxState({ ...checkboxState, pro: false });
        }}
      >
        <CardContent className={classes.cardContent}>
          <Grid
            container
            direction="row"
            alignContent="center"
            alignItems="center"
            className={classes.gridItemContainer}
          >
            <Grid item sm={2} className={classes.gridItemLeft}>
              <Checkbox
                className={classes.checkbox}
                checked={checkboxState.pro}
                value="pro"
                color="primary"
                inputProps={{
                  "aria-label": "checkbox pro",
                }}
              />
            </Grid>
            <Grid item sm={10} className={classes.gridItemRight}>
              <Grid container direction="column">
                <Grid item>{data.name}</Grid>
                <Grid item>
                  <Typography className={classes.pricing}>
                    {yearlyPlanData.currency.toUpperCase()}{" "}
                    {(
                      parseInt(yearlyPlanData.amount).toFixed(2) /
                      100 /
                      12
                    ).toFixed(2)}{" "}
                    / {t("general.label.month")}
                  </Typography>
                  <Typography className={classes.benefits}>
                    {t("general.label.pro.benefits")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ) : null;
  };

  /**
   * displayPlans
   *
   * @param plan
   * @returns {null|*}
   */
  const displayPlans = (plan) => {
    switch (plan.metadata.FeatureSet.toLowerCase()) {
      case UserTypes.FREE:
        return FreePlanCard(plan);
      case UserTypes.SMART:
        return SmartPlanCard(plan);
      case UserTypes.PRO:
        return ProPlanCard(plan);
      default:
        return null;
    }
  };

  /**
   * showTrialHasEnded
   *
   * @returns {string}
   */
  const showTrialHasEnded = () => {
    if (
      user.getData().company.stripeIsTrialing &&
      user.getData().company.stripeTrialingDays === 0
    ) {
      return (
        <Grid item className={classes.trialEndedNotification}>
          {t("general.label.trial.ended")}
        </Grid>
      );
    }
  };

  /**
   * return
   */
  return (
    <>
      <Grid item className={clsx(classes.item, classes.itemHeader)}>
        <h1 className={classes.title}>{t("general.label.premium.upgrade")}</h1>
        {result.fetching && (
          <CircularProgress className={classes.loadingProgress} />
        )}
      </Grid>
      {showTrialHasEnded()}
      <Grid
        item
        className={clsx(classes.item, classes.itemHeader, classes.planList)}
      >
        {stripeProductData.map((item) => displayPlans(item))}
        {(fetching || resultMyself.fetching) && <LoadingOverlay />}
      </Grid>
      <Grid
        item
        className={clsx(classes.item, classes.itemHeader, classes.link)}
      >
        <Link
          variant="body2"
          underline="always"
          target="_blank"
          href={getAbsoluteLink(cmsLinks, "CmsMenu", externalLinkTitles.PRICES)}
        >
          {!result.fetching && t("form.label.further.tariff.information")}
        </Link>
        {!result.fetching && (
          <Typography className={classes.taxHint}>
            {t("general.label.tax.hint")}
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default withAccessControl(
  FreeToPremiumDialogueStep0,
  ACL_COMPONENTS.FREE_TO_PREMIUM_DIALOGUE_STEP_0
);
