import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import Joi from "@hapi/joi";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FreeToPremiumStepper from "./FreeToPremiumStepper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormValueErrorsBuilder from "../../lib/FormValueErrorsBuilder";
import GetSafe from "../../lib/GetSafe";
import withAccessControl from "../HOC/AccessControl";
import GlobalContext from "../../lib/GlobalContext";
import { useMutation, useQuery } from "urql";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Switch from "@material-ui/core/Switch";
import UserTypes from "../Onboarding/UserTypes";
import Link from "@material-ui/core/Link";
import { getAbsoluteLink, externalLinkTitles } from "../../lib/CmsLinkHandler";
import { MESSAGE_TYPES } from "../PopupMessages";
import { navigate } from "hookrouter";
import { ACL_COMPONENTS, STRIPE_PLAN_IDS } from "../../config";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import LoadingOverlay from "../LoadingOverlay";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
    },
  },
  formControl: {
    justifyContent: "center",
    width: "100%",
    maxWidth: 330,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: "left",
  },
  formControlTerm: {
    maxWidth: 540,
    width: 520,
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  checkboxControl: {
    marginRight: 0,
    [theme.breakpoints.down("sm")]: {
      hyphens: "auto",
    },
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: "30px",
  },
  intro: {
    fontSize: 16,
    lineHeight: "24px",
    textAlign: "left",
  },
  item: {
    width: "100%",
    maxWidth: 330,
    margin: "0 auto",
  },
  itemTerm: {
    paddingRight: 10,
    paddingLeft: 10,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  itemTermSwitcher: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      margin: "10px auto",
    },
  },
  itemTermSwitch: {
    maxWidth: 504,
  },
  itemStepper: {
    maxWidth: 600,
  },
  itemHeader: {
    maxWidth: 444,
    paddingTop: 44,
    paddingBottom: 24,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      paddingBottom: 20,
      textAlign: "left",
    },
  },
  itemLabel: {
    fontSize: 14,
    color: "#999",
    fontWeight: "bold",
  },
  buttonNext: {
    width: "100%",
    color: "#fff",
    marginRight: "auto",
    fontWeight: "bold",
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
  },
  pricing: {
    fontSize: 24,
    color: theme.palette.text.primary,
    fontWeight: "bold",
    padding: "10px 0",
  },
  strikePrice: {
    fontSize: 24,
    color: theme.palette.text.primary,
    fontWeight: "bold",
    padding: "10px 0",
    textDecoration: "line-through",
  },
  benefits: {
    fontSize: 14,
    color: theme.palette.text.primary,
    fontWeight: "normal",
  },
  trialNotificationBox: {
    marginBottom: 10,
  },
  trialNotification: {
    fontSize: 14,
    fontWeight: "normal",
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main,
    height: 32,
    display: "table-cell",
    verticalAlign: "middle",
    padding: "5px 10px 3px 10px",
    marginBottom: 10,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  taxHint: {
    fontSize: 12,
    color: "#525252",
    fontWeight: "normal",
    padding: "0 0 10px 0",
  },
  trialHint: {
    fontSize: 14,
    color: "#525252",
    fontWeight: "normal",
    padding: "0 0 10px 0",
  },
}));

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.primary.main,
  },
  checked: {},
}))(Switch);

const updateCompanyMutation = `
  mutation UpdateCompany(
    $hashId: String!,
    $name: String,
    $street: String,
    $zip: String,
    $city: String,
    $lat: Float,
    $lon: Float,
    $phone: String,
    $fax: String,
    $website: String,
    $email: String,
    $businessActivityID: ID,
    $businessSpecialties: [BusinessSpecialityInput]
    $futurePlanID: String
  ) {
    updateCompany(
      HashID: $hashId, 
      Name: $name,
      Street: $street,
      Zip: $zip,
      City: $city,
      Latitude: $lat,
      Longitude: $lon,
      Phone: $phone,
      Fax: $fax,
      Website: $website,
      Email: $email,
      BusinessActivityID: $businessActivityID
      BusinessSpecialities: $businessSpecialties
      FuturePlanID: $futurePlanID
    ) {
      Name
      Street
      Zip
      City
      Latitude
      Longitude
      Phone
      Fax
      Website
      Email
      BusinessActivityID
    }
  }
`;

const stripeProductsQuery = `
  {
    readProduct {
      id
      description
      metadata {
        FeatureSet
      }
      name
      plans {
        id
        nickname
        amount
        currency
        interval
        interval_count
        active
        trial_period_days
        trial_possible
      }
    }
  }
`;

const LOADING_OVERLAY_TIMEOUT = 5000;

/**
 * FreeToPremiumDialogueStep3
 *
 * @returns {*}
 * @constructor
 */
const FreeToPremiumDialogueStep3 = (props) => {
  const MONTHLY = "monthly";
  const YEARLY = "yearly";
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    user,
    token,
    setToken,
    cmsLinks,
    setMessage,
    unsetUser,
    unsetToken,
  } = useContext(GlobalContext);
  const [formValues, setFormValues] = useState({
    chosenPlan: YEARLY,
    terms: false,
  });
  const [formValueErrors, setFormValueErrors] = useState({
    terms: { hasError: false, message: "" },
  });
  const [termSwitchState, setTermSwitchState] = useState(true);
  const [stripeProductData, setStripeProductData] = useState(null);
  const [loadingOverlayOpen, setLoadingOverlayOpen] = useState(false);

  const [{ fetching }, executeUpdateCompanyMutation] = useMutation(
    updateCompanyMutation
  );
  
  const [stripeProductsQueryResult, executeStripeProductsQuery] = useQuery({
    query: stripeProductsQuery,
    requestPolicy: "network-only",
    pause: true,
  });

  useEffect(() => {
    setFormValues(props.formData.step3);
  }, [props.formData.step3]);

  useEffect(() => {
    if (Object.keys(props.formData.step0.plans.monthly).length === 0) {
      executeStripeProductsQuery();
    }
  }, [props.formData.step0, executeStripeProductsQuery, token, setToken]);

  const schema = Joi.object({
    chosenPlan: Joi.string(),
    terms: Joi.boolean().invalid(false),
  });

  useEffect(() => {
    if (!stripeProductsQueryResult.fetching) {
      if (stripeProductsQueryResult.error) {
        // Check if the user need to be logged out
        if (
          stripeProductsQueryResult.error.message.indexOf(
            "User forced logout"
          ) !== -1
        ) {
          setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
          unsetUser();
          unsetToken();
          navigate("/");
        } else {
          // The query did not return any results!
          ExternalErrorLogger.log({
            text: "Error fetching stripe products data on FreeToPremiumDialogueStep3",
            data: {
              token: JSON.stringify(token.getData()),
              user: JSON.stringify(user.getData()),
              errorMessage: stripeProductsQueryResult.error.message,
            },
          });
        }
      } else {
        // Query not fetching right now
        if (
          typeof stripeProductsQueryResult.data !== "undefined" &&
          typeof stripeProductsQueryResult.data.readProduct !== "undefined" &&
          !stripeProductData
        ) {
          let plan = null;
          stripeProductsQueryResult.data.readProduct.map((item) => {
            if (
              typeof item.metadata.FeatureSet !== "undefined" &&
              item.metadata.FeatureSet.toLowerCase().includes(
                user.getData().mode
              )
            ) {
              plan = item;
            }
            return item;
          });

          let monthlyPlanData = {};
          let yearlyPlanData = {};
          plan.plans.map((item) => {
            if (item.id.includes("monthly")) {
              monthlyPlanData = item;
            } else if (
              item.id.includes(STRIPE_PLAN_IDS.SMART.YEARLY) ||
              item.id.includes(STRIPE_PLAN_IDS.PRO.YEARLY)
            ) {
              yearlyPlanData = item;
            }
            return item;
          });

          if (!stripeProductData) {
            setStripeProductData(plan);
          }

          props.setFormData(
            Object.assign({}, props.formData, {
              step0: {
                plans: { monthly: monthlyPlanData, yearly: yearlyPlanData },
              },
            })
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeProductsQueryResult]);

  /**
   * handleFromSubmit
   */
  const handleFormSubmit = () => {
    const { error } = schema.validate(formValues, { abortEarly: false });
    if (error) {
      const formErrors = FormValueErrorsBuilder(error, t);
      setFormValueErrors({ ...formErrors });
    } else {
      props.setFormData(
        Object.assign({}, props.formData, { step3: formValues })
      );

      if (
        props.showSmartTrialNotification(
          props.formData.step0.plans[formValues.chosenPlan].trial_period_days,
          props.formData.step0.plans[formValues.chosenPlan].trial_possible
        )
      ) {
        let branchSubsRefined = [];

        if (props.formData.step2.branchSubs) {
          props.formData.step2.branchSubs.map((item) => {
            branchSubsRefined.push({
              ID: item,
            });
            return item;
          });
        }

        if (!branchSubsRefined.length) {
          branchSubsRefined = null;
        }

        const showLoadingOverlayTimeout = setTimeout(
          () => setLoadingOverlayOpen(true),
          LOADING_OVERLAY_TIMEOUT
        );

        executeUpdateCompanyMutation({
          hashId: user.getData().company.hashID,
          name: props.formData.step1.company || user.getData().company.name,
          street: props.formData.step1.street || user.getData().company.street,
          zip: props.formData.step1.zip || user.getData().company.zip,
          city: props.formData.step1.city || user.getData().company.city,
          lat: props.formData.step1.lat || user.getData().company.latitude,
          lon: props.formData.step1.lon || user.getData().company.longitude,
          phone: props.formData.step1.phone || user.getData().company.phone,
          fax: props.formData.step1.fax || user.getData().company.fax,
          website:
            props.formData.step1.website || user.getData().company.website,
          email: props.formData.step1.email || user.getData().company.email,
          futurePlanID: props.formData.step0.plans[formValues.chosenPlan].id,
          businessActivityID:
            props.formData.step2.branchCategory ||
            user.getData().company.branchCategory,
          businessSpecialties: branchSubsRefined,
        }).then((result) => {
          clearTimeout(showLoadingOverlayTimeout);
          setLoadingOverlayOpen(false);

          if (result.error) {
            // Check if the user need to be logged out
            if (result.error.message.indexOf("User forced logout") !== -1) {
              setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
              unsetUser();
              unsetToken();
              navigate("/");
            } else {
              if (
                result.error.message.indexOf(
                  "The selected plan has already been subscribed"
                ) !== -1
              ) {
                setMessage(MESSAGE_TYPES.ERROR, t("error.subsription.exists"));
              } else {
                setMessage(MESSAGE_TYPES.ERROR, t("error.general"));
              }
              ExternalErrorLogger.log({
                text: "Error save data on FreeToPremiumDialogueStep3",
                data: {
                  token: JSON.stringify(token.getData()),
                  user: JSON.stringify(user.getData()),
                  errorMessage: result.error.message,
                },
              });
            }
          } else {
            props.setStep(5)
          }
        });
      } else {
        props.setStep(4);
      }
    }
  };

  /**
   * handleCheckTermsChange
   *
   * @param event
   */
  const handleCheckTermsChange = (event) => {
    setFormValues(
      Object.assign({}, formValues, { terms: event.target.checked })
    );
  };

  /**
   * handlePlanChange
   */
  const handlePlanChange = () => {
    if (!termSwitchState) {
      setFormValues({ ...formValues, chosenPlan: YEARLY });
    } else {
      setFormValues({ ...formValues, chosenPlan: MONTHLY });
    }
    setTermSwitchState(!termSwitchState);
  };

  /**
   * determineChosenPlan
   *
   * @returns {*}
   */
  const determineChosenPlan = () => {
    const firstPlanID =
      props.formData.step0.plans[Object.keys(props.formData.step0.plans)[0]].id;

    if (firstPlanID.includes(UserTypes.SMART)) {
      return UserTypes.SMART;
    }
    if (firstPlanID.includes(UserTypes.PRO)) {
      return UserTypes.PRO;
    }
  };

  /**
   * renderChosenPlan
   *
   * @returns {*}
   */
  const renderChosenPlan = () => {
    if (determineChosenPlan() === UserTypes.SMART) {
      return <SmartPlanLabel />;
    }

    if (determineChosenPlan() === UserTypes.PRO) {
      return <ProPlanLabel />;
    }
  };

  /**
   * showPricePerMonth
   *
   * @returns {string}
   */
  const showPricePerMonth = () => {
    if (formValues.chosenPlan === MONTHLY) {
      return (
        parseInt(
          props.formData.step0.plans[formValues.chosenPlan].amount
        ).toFixed(2) /
          100 +
        " "
      );
    } else {
      return (
        Math.round(
          parseInt(
            props.formData.step0.plans[formValues.chosenPlan].amount
          ).toFixed(2) /
            100 /
            12
        ) + " "
      );
    }
  };

  /**
   * SmartPlanLabel
   *
   * @returns {*}
   * @constructor
   */
  const SmartPlanLabel = () => {
    return (
      <Grid container direction="column">
        <Grid item className={clsx(classes.item, classes.itemLabel)}>
          {t("general.label.smart")}
        </Grid>
        <Grid item>
          <Typography
            className={
              props.showSmartTrialNotification(
                props.formData.step0.plans[formValues.chosenPlan]
                  .trial_period_days,
                props.formData.step0.plans[formValues.chosenPlan].trial_possible
              )
                ? classes.strikePrice
                : classes.pricing
            }
          >
            {props.formData.step0.plans[
              formValues.chosenPlan
            ].currency.toUpperCase()}{" "}
            {showPricePerMonth()}/ {t("general.label.month")}
          </Typography>
          {props.showSmartTrialNotification(
            props.formData.step0.plans[formValues.chosenPlan].trial_period_days,
            props.formData.step0.plans[formValues.chosenPlan].trial_possible
          ) && (
            <>
              <div className={classes.trialNotificationBox}>
                <Typography className={classes.trialNotification}>
                  {t("general.label.days.of.testing", {
                    days: props.formData.step0.plans[formValues.chosenPlan]
                      .trial_period_days,
                  })}
                </Typography>
              </div>
              <Typography className={classes.trialHint}>
                {t("general.label.trial.hint")}
              </Typography>
            </>
          )}
          <Typography className={classes.taxHint}>
            {t("general.label.tax.hint")}
          </Typography>
          <Typography className={classes.benefits}>
            {t("general.label.one.user")}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  /**
   * ProPlanLabel
   *
   * @returns {*}
   * @constructor
   */
  const ProPlanLabel = () => {
    return (
      <Grid container direction="column">
        <Grid item className={clsx(classes.item, classes.itemLabel)}>
          {t("general.label.pro")}
        </Grid>
        <Grid item>
          <Typography className={classes.pricing}>
            {props.formData.step0.plans[
              formValues.chosenPlan
            ].currency.toUpperCase()}{" "}
            {showPricePerMonth()}/ {t("general.label.month")}
          </Typography>
          <Typography className={classes.taxHint}>
            {t("general.label.tax.hint")}
          </Typography>
          <Typography className={classes.benefits}>
            {t("general.label.pro.benefits")}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  /**
   * showTermSwitch
   *
   * @returns {boolean}
   */
  const showTermSwitch = () => {
    // let showSwitch = true
    let showSwitch = false; // Disabled so customers could never switch to monthly terms
    if (
      props.showSmartTrialNotification(
        props.formData.step0.plans[formValues.chosenPlan].trial_period_days,
        props.formData.step0.plans[formValues.chosenPlan].trial_possible
      ) &&
      props.formData.step0.plans[formValues.chosenPlan].id.includes("smart")
    ) {
      showSwitch = false;
    }

    return showSwitch;
  };

  /**
   * return
   */
  return (
    <>
      <Grid item className={clsx(classes.item, classes.itemHeader)}>
        <h1 className={classes.title}>{t("general.label.premium.upgrade")}</h1>
        <p className={classes.intro}>{t("onboarding.intro")}</p>
      </Grid>
      <Grid item className={clsx(classes.item, classes.itemStepper)}>
        <FreeToPremiumStepper zeroBasedStep={props.step - 1} />
      </Grid>
      {Object.keys(props.formData.step0.plans.monthly).length === 0 && (
        <Grid item>
          <CircularProgress />
        </Grid>
      )}
      {Object.keys(props.formData.step0.plans.monthly).length !== 0 && (
        <>
          {showTermSwitch() && (
            <Grid item className={clsx(classes.item, classes.itemTermSwitch)}>
              <Grid
                component="label"
                container
                alignItems="center"
                className={classes.formControlTerm}
              >
                <Grid item className={classes.itemTerm}>
                  {t("general.label.monthly.term")}
                </Grid>

                <Grid item className={classes.itemTermSwitcher}>
                  <AntSwitch
                    checked={termSwitchState}
                    onChange={handlePlanChange}
                    value={formValues.chosenPlan}
                  />
                </Grid>

                <Grid item className={classes.itemTerm}>
                  {t("general.label.yearly.term")}
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item className={classes.item}>
            <Grid item className={classes.formControl}>
              <FormControl required component="fieldset">
                <FormControlLabel
                  className={classes.checkboxControl}
                  control={
                    <Checkbox
                      checked
                      value="smart"
                      color="primary"
                      inputProps={{
                        "aria-label": "checkbox smart",
                      }}
                    />
                  }
                  label={renderChosenPlan()}
                />
              </FormControl>
            </Grid>
            <Grid item className={classes.item}>
              <Grid item className={classes.formControl}>
                <FormControl
                  required
                  error={GetSafe(() => formValueErrors.terms.hasError, false)}
                  component="fieldset"
                >
                  <FormControlLabel
                    className={classes.checkboxControl}
                    control={
                      <Checkbox
                        id="checkbox-condition-1"
                        checked={formValues.terms}
                        color="primary"
                        onChange={handleCheckTermsChange}
                        value={1}
                      />
                    }
                    label={
                      <div>
                        {t("form.label.i.accept")}{" "}
                        <Link
                          target="_blank"
                          href={getAbsoluteLink(
                            cmsLinks,
                            "CmsMenu",
                            externalLinkTitles.CONDITIONS
                          )}
                        >
                          {t("footer.label.conditions")}
                        </Link>
                      </div>
                    }
                  />
                  {GetSafe(() => formValueErrors.terms.hasError, false) && (
                    <FormHelperText id="checkbox-condition-1-helper-text">
                      {formValueErrors.terms.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item className={clsx(classes.item, classes.buttonContainer)}>
              <Button
                id="buttonFormNext"
                variant="contained"
                color="primary"
                className={classes.buttonNext}
                onClick={() => {
                  handleFormSubmit();
                }}
                disabled={fetching}
              >
                {props.showSmartTrialNotification(
                  props.formData.step0.plans[formValues.chosenPlan]
                    .trial_period_days,
                  props.formData.step0.plans[formValues.chosenPlan]
                    .trial_possible
                )
                  ? t("general.label.start.test")
                  : t("general.label.premium.order")}
                {fetching && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
              {loadingOverlayOpen && (
                <LoadingOverlay
                  text={t("general.label.preparing.building.applications")}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default withAccessControl(
  FreeToPremiumDialogueStep3,
  ACL_COMPONENTS.FREE_TO_PREMIUM_DIALOGUE_STEP_3
);
