import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import FreeToPremiumStepper from "./FreeToPremiumStepper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Lock from "@material-ui/icons/Lock";
import GlobalContext from "../../lib/GlobalContext";
import { useMutation } from "urql";
import withAccessControl from "../HOC/AccessControl";
import { MESSAGE_TYPES } from "../PopupMessages";
import { navigate } from "hookrouter";
import { ACL_COMPONENTS } from "../../config";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import Payment from "../Payment/Payment";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4, 4),
    marginBottom: theme.spacing(3),
    // backgroundColor: '#eee'
  },
  paymentCardImage: {
    display: "inline-block",
    transition: "all .4s ease-out",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  paymentCardContainer: {
    width: "100%",
    maxWidth: 330,
    margin: "0 auto",
  },
  paymentLabelTop: {
    fontSize: 14,
    fontWeight: "bold",
  },
  title: {
    margin: 0,
    fontSize: 24,
    lineHeight: "30px",
  },
  intro: {
    fontSize: 16,
    lineHeight: "24px",
    textAlign: "left",
  },
  item: {
    width: "100%",
    maxWidth: 330,
    margin: "0 auto",
  },
  itemStepper: {
    maxWidth: 600,
  },
  itemHeader: {
    maxWidth: 444,
    paddingTop: 44,
    paddingBottom: 24,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      paddingBottom: 20,
      textAlign: "left",
    },
  },
  formFieldContainer: {
    width: "100%",
  },
  paperContainer: {
    width: "100%",
    maxWidth: 590,
    paddingTop: 40,
    paddingRight: 120,
    paddingBottom: 40,
    paddingLeft: 120,
    borderRadius: 5,
    backgroundColor: "#efeff1",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    flexBasis: "100%",
  },
  preciseTag: {
    alignSelf: "flex-start",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  iconButton: {
    padding: 0,
  },
  paperHeadLeft: {
    textAlign: "left",
  },
  paperHeadRight: {
    textAlign: "right",
  },
  paperFooter: {
    position: "relative",
    color: "#aaa",
  },
  lock: {
    display: "block",
    position: "relative",
    width: 75,
    margin: "auto",
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: "#efeff1",
    zIndex: "2",
  },
  line: {
    position: "absolute",
    top: "50%",
    width: "100%",
    height: "1px",
    backgroundColor: "#aaa",
    transform: "translateY(-50%)",
  },
  secureText: {
    color: "#666",
    marginTop: theme.spacing(2),
  },
  textPremiumSecure: {
    display: "block",
    fontSize: 12,
    textAlign: "center",
  },
  priceTag: {
    textAlign: "right",
    backgroundColor: "#E2E2E4",
    padding: theme.spacing(1),
    borderRadius: "4px",
    marginBottom: theme.spacing(1),
  },
}));

const updateCompanyMutation = `
  mutation UpdateCompany(
    $hashId: String!,
    $name: String,
    $street: String,
    $zip: String,
    $city: String,
    $lat: Float,
    $lon: Float,
    $phone: String,
    $fax: String,
    $website: String,
    $email: String,
    $businessActivityID: ID,
    $businessSpecialties: [BusinessSpecialityInput]
    $futurePlanID: String,
    $paymentMethodID: String!
  ) {
    updateCompany(
      HashID: $hashId, 
      Name: $name,
      Street: $street,
      Zip: $zip,
      City: $city,
      Latitude: $lat,
      Longitude: $lon,
      Phone: $phone,
      Fax: $fax,
      Website: $website,
      Email: $email,
      BusinessActivityID: $businessActivityID
      BusinessSpecialities: $businessSpecialties
      FuturePlanID: $futurePlanID,
      PaymentMethodID: $paymentMethodID
    ) {
      Name
      Street
      Zip
      City
      Latitude
      Longitude
      Phone
      Fax
      Website
      Email
      BusinessActivityID
    }
  }
`;

/**
 * FreeToPremiumDialogueStep4
 *
 * @returns {*}
 * @constructor
 */
const FreeToPremiumDialogueStep4 = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    user,
    token,
    setToken,
    paymentMethodId,
    setMessage,
    unsetUser,
    unsetToken,
  } = useContext(GlobalContext);

  const [{ fetching }, executeUpdateCompanyMutation] = useMutation(updateCompanyMutation);

  useEffect(() => {
    if (paymentMethodId !== null) {
      let branchSubsRefined = [];

      if (props.formData.step2.branchSubs) {
        props.formData.step2.branchSubs.map((item) => {
          branchSubsRefined.push({
            ID: item,
          });
          return item;
        });
      }

      if (!branchSubsRefined.length) {
        branchSubsRefined = null;
      }

      executeUpdateCompanyMutation({
        hashId: user.getData().company.hashID,
        name: props.formData.step1.company || user.getData().company.name,
        street: props.formData.step1.street || user.getData().company.street,
        zip: props.formData.step1.zip || user.getData().company.zip,
        city: props.formData.step1.city || user.getData().company.city,
        lat: props.formData.step1.lat || user.getData().company.latitude,
        lon: props.formData.step1.lon || user.getData().company.longitude,
        phone: props.formData.step1.phone || user.getData().company.phone,
        fax: props.formData.step1.fax || user.getData().company.fax,
        website: props.formData.step1.website || user.getData().company.website,
        email: props.formData.step1.email || user.getData().company.email,
        futurePlanID:
          props.formData.step0.plans[props.formData.step3.chosenPlan].id,
        paymentMethodID: paymentMethodId,
        businessActivityID:
          props.formData.step2.branchCategory ||
          user.getData().company.branchCategory,
        businessSpecialties: branchSubsRefined,
      }).then((result) => {
        if (result.error) {
          // Check if the user need to be logged out
          if (result.error.message.indexOf("User forced logout") !== -1) {
            setMessage(MESSAGE_TYPES.ERROR, t("error.user.forced.logout"));
            unsetUser();
            unsetToken();
            navigate("/");
          } else {
            ExternalErrorLogger.log({
              text: "Error save myself on FreeToPremiumDialogueStep4",
              data: {
                token: JSON.stringify(token.getData()),
                user: JSON.stringify(user.getData()),
                errorMessage: result.error.message,
              },
            });
            if (
              result.error.message.indexOf(
                "The selected plan has already been subscribed"
              ) !== -1
            ) {
              setMessage(MESSAGE_TYPES.ERROR, t("error.subsription.exists"));
            } else {
              setMessage(MESSAGE_TYPES.ERROR, t("error.general"));
            }
            props.setStep(3);
          }
        } else {
          props.setStep(5)
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paymentMethodId,
    executeUpdateCompanyMutation,
    props,
    setToken,
    token,
    user,
    setMessage,
    unsetUser,
    unsetToken,
    t,
  ]);

  const showPriceWithTax = () => {
    let amount = parseInt(
      props.formData.step0.plans[props.formData.step3.chosenPlan].amount
    );

    // TODO: tax value has to come from api!
    amount += amount * 0.081

    return (amount.toFixed(2) / 100).toFixed(2);
  };

  /**
   * return
   */
  return (
    <>
      <Grid item className={clsx(classes.item, classes.itemHeader)}>
        <h1 className={classes.title}>{t("general.label.premium.upgrade")}</h1>
        <p className={classes.intro}>{t("onboarding.intro")}</p>
      </Grid>
      <Grid item className={clsx(classes.item, classes.itemStepper)}>
        <FreeToPremiumStepper zeroBasedStep={2} />
      </Grid>
      <Grid item className={classes.paperContainer}>
        <Grid
          container
          direction="row"
          className={classes.paymentCardContainer}
        >
          <Grid item className={clsx(classes.item, classes.paperHeadLeft)}>
            <Typography
              variant="subtitle1"
              gutterBottom
              className={classes.paymentLabelTop}
            >
              {t("general.label.premium.choosePayment")}
            </Typography>
          </Grid>
          <Grid item className={classes.item}>
            <div className={classes.priceTag}>
              {props.formData.step0.plans[
                props.formData.step3.chosenPlan
              ].currency.toUpperCase()}{" "}
              {showPriceWithTax()}{" "}
            </div>
          </Grid>
        </Grid>
        <Payment
          user={user}
          fetching={fetching}
          plan={props.formData.step3.chosenPlan}
        />
        <Grid item className={clsx(classes.item, classes.paperFooter)}>
          <div className={classes.line}> </div>
          <Lock className={[classes.icon, classes.lock].join(" ")} />
        </Grid>
        <Grid item className={clsx(classes.item, classes.secureText)}>
          <Typography
            variant="caption"
            gutterBottom
            className={classes.textPremiumSecure}
          >
            {t("general.label.premium.secure")}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default withAccessControl(
  FreeToPremiumDialogueStep4,
  ACL_COMPONENTS.FREE_TO_PREMIUM_DIALOGUE_STEP_4
);
