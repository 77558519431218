import React, { useContext, useState, useEffect } from "react";
import clsx from "clsx";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useTranslation } from "react-i18next";
import GlobalContext from "../lib/GlobalContext";
import Button from "@material-ui/core/Button";
import { Card, CardContent, DialogTitle, makeStyles } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Joi from "@hapi/joi";
import FormValueErrorsBuilder from "../lib/FormValueErrorsBuilder";
import GetSafe from "../lib/GetSafe";
import { useQuery } from "urql";
import FormHelperText from "@material-ui/core/FormHelperText";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import { externalLinkTitles, getAbsoluteLink } from "../lib/CmsLinkHandler";
import { MESSAGE_TYPES } from "../components/PopupMessages";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { parsePermissions } from "../components/HOC/AccessControl";
import UserTypes from "../components/Onboarding/UserTypes";
import {
  COOKIE_LIFETIME_DAYS,
  cookieDomainRedirect,
  STRIPE_PLAN_IDS,
  FORCE_MODE,
  isDev,
  DEFAULT_LANGUAGE,
} from "../config";
import Cookies from "universal-cookie";
import ExternalErrorLogger from "@ennit/react-external-errorlogger";
import FormatHelper from "../lib/FormatHelper";

/**
 * useStyles
 */
const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "100%",
    padding: 56,
    "&::before": theme.watermarkLogo,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingBottom: 0,
  },
  title: {
    margin: 0,
    paddingTop: 44,
    paddingBottom: 24,
    fontSize: 24,
    lineHeight: "30px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      paddingBottom: 20,
      textAlign: "left",
    },
  },
  item: {
    width: "100%",
    maxWidth: 330,
    paddingTop: 20,
    paddingBottom: 20,
  },
  itemHeader: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemPassword: {
    paddingTop: 0,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 0,
    },
  },
  itemNoAccount: {
    paddingBottom: 0,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
  },
  notice: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  button: {
    width: "100%",
    maxWidth: 330,
    color: "#fff",
    fontWeight: "bold",
  },
  textField: {
    width: "100%",
    maxWidth: 330,
  },
  error: {
    color: "red",
    margin: 0,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  card: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const queryMyself = `
{
  readMyself {
    ID
    HashID
    Locale
    Email
    Firstname
    Surname
    Salutation
    StripePublicKey
    AppAdmin
    IsMasquerade
    CreditCard {
      id
      name
      last4
      expired
    }
    Locations {
      edges {
        node {
          HashID
          Title
          Street
          Zip
          City
          Longitude
          Latitude
          Radius
          Version
          Sort
          Removed
          ConstructionSumList {
            edges {
              node {
                Code
              }
            }
          }
        }
      }
    }
    Company {
      ID
      AdminUserID
      HashID
      FeatureSet
      ZipSearchAreas
      ZipSearchStatistic
      ApplicationFollowUpTimeInDays
      StripeIsTrialing
      StripeTrialingDays
      StripePlanID
      StripeValidThrough
      StripeProductID
      StripeCancelAt
      Name
      URLSegment
      ContactPerson
      Street
      Zip
      City
      Canton
      Distance
      Latitude
      Longitude
      Phone
      Fax
      Website
      Email
      NumberOfEmployees
      LegalForm
      FoundingYear
      MasterBusiness
      TrainingCompany
      BusinessActivityID
      BusinessSpecialities{
        edges {
          node {
            ID
            Title
            NogaCode
            SCCodeCompetence
            BoostScore
          }
        }
      }
      OtherBusinessActivities {
        edges {
          node {
            ID
          }
        }
      }
      OtherBusinessSpecialities {
        edges {
          node {
            ID
          }
        }
      }
      User{
        edges {
          node {
            HashID
            Email
            Firstname
            Surname
            Removed
            SearchAreas {
              HashID
              Title
              ZipList
            },
            DossierTemplates {
              edges {
                node {
                  HashID,
                  TemplateName
                }
              }
            }
          }
        }
      }
      Memberships {
        edges {
          node {
            ID
            Title
            LogoUrl
          }
        }
      }
      AllMemberships {
        edges {
          node {
            ID
            Title
            LogoUrl
            IsBadge
          }
        }
      }
      MicrositeCertified
      MicrositeCertifiedUntil
      AdditionalMemberships
      MicrositeColorScheme
      MicrositeDescription
      MicrositeEmailApplicationText
      MicrositeImages {
        edges {
          node {
            Src
            Title
            Alt
          }
        }
      }
      MicrositeLogo {
        Src
        Title
        Alt
      }
      MicrositeReferences {
          edges {
            node {
              Sort
              Title
              Content
              Location
              Year
              Month
              ButtonLink
              ButtonTitle
              MicrositeReferencesImages {
              edges { 
                node {
                  Src
                  Title
                  Alt
                }
              }
            }
          }
        }
      }
      ApiAccess {
        edges {
          node {
            RequestUrl
          }
        } 
      }
      ConstructionSumList {
        edges {
          node {
            Code
          }
        }
      } 
    }
    DossierTemplates {
      edges {
        node {
          HashID
          TemplateName
          MicrositeEmailApplicationText
        }
      }
    }
    Permissions {
      Name
      RequireAdminStatus
    }
    FulltextSearchRequests {
      ID
      Title
    }
    SearchAreas {
      HashID
      Title
      ZipList
    }
  }
  readProduct {
    plans {
      id
      trial_possible
    }
  }
}
`;

const queryPasswordLost = `
  query PasswordLost (
    $email: String
  ) {
    passwordLost(Email: $email) {
      response
    }
  }
`;

/**
 * Login
 *
 * @returns {*}
 * @constructor
 */
const Login = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [authorizing, setAuthorizing] = useState(false);
  const initialFormErrors = {
    email: { hasError: false, message: "" },
    password: { hasError: false, message: "" },
    login: { hasError: false, message: t("error.login") },
  };
  const [formValueErrors, setFormValueErrors] = useState(initialFormErrors);
  const {
    user,
    setUser,
    token,
    setToken,
    tokenHash,
    unsetUser,
    unsetToken,
    cmsLinks,
    setMessage,
    setProjectData,
    setSelectedLocationTabValue,
    setSelectedBuildingApplicationTypeTabValue
  } = useContext(GlobalContext);
  
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [showPasswordForgotDialog, setShowPasswordForgotDialog] =
    useState(false);
  const [showMailSendMessage, setShowMailSendMessage] = useState(false);

  /**
   * Joi validation schema
   */
  const schema = Joi.object({
    password: Joi.string().required(),
    showPassword: Joi.boolean(),
    email: Joi.string().email({ tlds: false }).required(),
  });

  /**
   * Joi validation passwordForgotSchema
   */
  const passwordForgotSchema = Joi.object({
    password: Joi.string().allow(""),
    showPassword: Joi.boolean(),
    email: Joi.string().email({ tlds: false }).required(),
  });

  /**
   * GraphQL query for user-data (myself), gets triggered by calling "executeMyselfQuery()"
   */
  const [myselfResult, executeMyselfQuery] = useQuery({
    query: queryMyself,
    variables: {
      language: DEFAULT_LANGUAGE,
      token: token.getData().access_token,
    }, // Required as cache-control mechanism
    requestPolicy: "network-only",
    pause: true,
  });

  /**
   * GraphQL query for lost password , gets triggered by calling "executePasswordLostQuery()"
   */
  const [passwordLostResult, executePasswordLostQuery] = useQuery({
    query: queryPasswordLost,
    variables: { email: formValues.email },
    requestPolicy: "network-only",
    pause: true,
  });

  useEffect(() => {
    setSelectedLocationTabValue(false)
    setSelectedBuildingApplicationTypeTabValue(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * useEffect
   */
  useEffect(() => {
    if (!myselfResult.fetching && token.isSet() && !user.isSet()) {
      executeMyselfQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, tokenHash]);

  /**
   * resetFormErrors
   */
  const resetFormErrors = () => {
    setFormValueErrors({ ...initialFormErrors });
  };

  /**
   * triggerLoginError
   */
  const triggerLoginError = () => {
    setAuthorizing(false);
    setFormValueErrors({
      ...initialFormErrors,
      login: { hasError: true, message: t("error.login") },
    });
  };

  /**
   * getUserMode
   *
   * Determine user feature-set, either a forced set (debug/dev) or the stripe-feature set
   *
   * @param readMyselfResult
   * @returns string
   */
  const getUserMode = (readMyselfResult) => {
    // Feature-set override for debugging or dev purpose
    if (isDev && FORCE_MODE !== null) {
      return FORCE_MODE;
    }

    // Stripe feature-set via gql api
    return readMyselfResult.data.readMyself[0].Company.FeatureSet === null
      ? null
      : readMyselfResult.data.readMyself[0].Company.FeatureSet.toLowerCase();
  };

  /**
   * loginUser
   *
   * Logs in the user using the username and password provided
   *
   * @param event
   * @returns {Promise<void>}
   */
  const loginUser = async (event) => {
    // Only if we have an event handle the default behaviour
    if (event) {
      event.preventDefault();
    }

    setProjectData([]);

    // Reset form errors
    // Because we use a custom error field (login) we need to reset the form,
    // because the login-error is validated and triggered by us, not Joi
    resetFormErrors();

    // Unset user
    unsetUser();
    unsetToken();

    // Validate form data
    const { error } = schema.validate(formValues, { abortEarly: false });

    if (error) {
      const formErrors = FormValueErrorsBuilder(error, t);
      setFormValueErrors({ ...formErrors });
    } else {
      // Start authorizing (loading indicator)
      setAuthorizing(true);
      try {
        const authorizationResponseData = await token.api.authorize(
          formValues.email,
          formValues.password
        );
        if (authorizationResponseData) {
          token.setData(authorizationResponseData);
          setToken(token);
          setAppRedirectCookie();
        } else {
          triggerLoginError();
        }
      } catch (error) {
        triggerLoginError();
        console.error(error);
      }
    }
  };

  /**
   * setAppRedirectCookie
   */
  const setAppRedirectCookie = () => {
    const cookies = new Cookies();
    if (typeof cookies.get("appRedirect") === "undefined") {
      const cookieLifetimeDate = new Date();
      cookieLifetimeDate.setDate(
        cookieLifetimeDate.getDate() + COOKIE_LIFETIME_DAYS
      ); // Add 30 days (refresh-token lifetime)
      cookies.set("appRedirect", cookieLifetimeDate.toUTCString(), {
        path: "/",
        domain: cookieDomainRedirect,
        expires: cookieLifetimeDate,
        SameSite: true,
      });
    }
  };

  /**
   * determineLoginPermission
   *
   * @param myselfData
   * @returns {boolean}
   */
  const determineLoginPermission = (myselfData) => {
    if (myselfData.Company.AdminUserID !== myselfData.ID) {
      return myselfData.Company.FeatureSet.toLowerCase() === UserTypes.PRO;
    } else {
      return true;
    }
  };

  /**
   * GraphQL trigger and myselfResult handling
   */
  useEffect(() => {
    if (!myselfResult.fetching && token.isSet() && !user.isSet()) {
      if (myselfResult.error) {
        // Check if the user need to be logged out
        if (myselfResult.error.message.indexOf("User forced logout") !== -1) {
          // Because we are in the login-process,
          // re-login the user in case of revoked token
          loginUser();
        } else {
          // Something went very wrong
          setAuthorizing(false);
          ExternalErrorLogger.log({
            text: "Error fetching myself on Login",
            data: {
              user: JSON.stringify(user.getData()),
              token: JSON.stringify(token.getData()),
              errorMessage: myselfResult.error.message,
            },
          });
          setMessage(MESSAGE_TYPES.ERROR, t("error.fetching.branches"));
        }
      } else {
        // Query not fetching right now
        if (
          typeof myselfResult.data !== "undefined" &&
          typeof myselfResult.data.readMyself !== "undefined"
        ) {
          if (determineLoginPermission(myselfResult.data.readMyself[0])) {
            const branchSubsData = [];
            myselfResult.data.readMyself[0].Company.BusinessSpecialities.edges.map(
              (item) => {
                branchSubsData.push(parseInt(item.node.ID));
                return branchSubsData;
              }
            );

            const otherBusinessActivities = [];
            myselfResult.data.readMyself[0].Company.OtherBusinessActivities.edges.map(
              (item) => {
                otherBusinessActivities.push(parseInt(item.node.ID));
                return otherBusinessActivities;
              }
            );

            const otherBusinessSpecialties = [];
            myselfResult.data.readMyself[0].Company.OtherBusinessSpecialities.edges.map(
              (item) => {
                otherBusinessSpecialties.push(parseInt(item.node.ID));
                return otherBusinessSpecialties;
              }
            );

            const memberships = [];
            myselfResult.data.readMyself[0].Company.Memberships.edges.map(
              (item) => {
                memberships.push(item.node);
                return memberships;
              }
            );

            const allMemberships = [];
            myselfResult.data.readMyself[0].Company.AllMemberships.edges.map(
              (item) => {
                allMemberships.push(item.node);
                return allMemberships;
              }
            );

            const micrositeImages = [];
            myselfResult.data.readMyself[0].Company.MicrositeImages.edges.map(
              (item) => {
                if (item.node?.Src && item.node?.Src.length) {
                  micrositeImages.push(item.node);
                }
                return micrositeImages;
              }
            );

            const locationData = [];
            myselfResult.data.readMyself[0].Locations.edges.map((item) => {
              const constructionSums = [];
              item.node.ConstructionSumList.edges.map((constructionSum) => {
                constructionSums.push(constructionSum.node.Code);
                return constructionSums;
              });

              item.node.ConstructionSums = constructionSums;

              locationData.push(item.node);
              return locationData;
            });

            locationData.sort((a, b) => {
              return a.Sort - b.Sort;
            });

            const companyUsersData = [];
            myselfResult.data.readMyself[0].Company.User.edges.map((item) => {
              if (item.node.HashID !== myselfResult.data.readMyself[0].HashID) {
                companyUsersData.push(item.node);
              }
              return companyUsersData;
            });

            companyUsersData.sort((a, b) => {
              if (a.Firstname.toLowerCase() < b.Firstname.toLowerCase()) {
                return -1;
              }
              if (a.Firstname.toLowerCase() > b.Firstname.toLowerCase()) {
                return 1;
              }
              return 0;
            });

            let trialPossible = false;

            myselfResult.data.readProduct.map((productItem) => {
              productItem.plans.map((item) => {
                if (item.id.includes(STRIPE_PLAN_IDS.SMART.YEARLY)) {
                  trialPossible = item.trial_possible;
                }
                return item;
              });
              return productItem;
            });

            const micrositeReferences = [];
            myselfResult.data.readMyself[0].Company.MicrositeReferences.edges.map(
              (item) => {
                delete item.node.__typename;
                const referenceImages = [];
                if (typeof item.node.MicrositeReferencesImages.edges !== 'undefined') {
                  item.node.MicrositeReferencesImages.edges.map((image) => {
                    delete image.node.__typename;
                    if (image.node?.Src && image.node?.Src.length) {
                      referenceImages.push(image.node);
                    }
                    return image;
                  });
                }
                item.node.MicrositeReferencesImages = referenceImages;

                micrositeReferences.push(item.node);
                return micrositeReferences;
              }
            );

            let hasExternalApply = false;
            myselfResult.data.readMyself[0].Company.ApiAccess.edges.map(
              (item) => {
                if (item.node.RequestUrl.includes("external")) {
                  hasExternalApply = true;
                }
                return item;
              }
            );

            const dossierTemplatesData = [];
            myselfResult.data.readMyself[0].DossierTemplates.edges.map((item) => {
              dossierTemplatesData.push(item.node);
              return dossierTemplatesData;
            });

            // Map the user-data
            user.setData({
              id: myselfResult.data.readMyself[0].ID,
              hashID: myselfResult.data.readMyself[0].HashID,
              locale: myselfResult.data.readMyself[0].Locale || "",
              email: myselfResult.data.readMyself[0].Email,
              firstName: myselfResult.data.readMyself[0].Firstname,
              lastName: myselfResult.data.readMyself[0].Surname,
              salutation: myselfResult.data.readMyself[0].Salutation || "",
              appAdmin: myselfResult.data.readMyself[0].AppAdmin || 0,
              isMasquerade: myselfResult.data.readMyself[0].IsMasquerade || 0,
              mode: getUserMode(myselfResult),
              stripePublicKey:
                myselfResult.data.readMyself[0].StripePublicKey || "",
              creditCard: myselfResult.data.readMyself[0].CreditCard,
              locations: locationData || [],
              company: {
                id: myselfResult.data.readMyself[0].Company.ID,
                hashID: myselfResult.data.readMyself[0].Company.HashID,
                adminUserID: myselfResult.data.readMyself[0].Company.AdminUserID,
                name:
                  myselfResult.data.readMyself[0].Company.Name ||
                  "TODO COMPANY-NAME", // TODO: Remove fallback company name for production (requires consistent data)
                urlSegment: myselfResult.data.readMyself[0].Company.URLSegment,
                contactPerson:
                  myselfResult.data.readMyself[0].Company.ContactPerson || "",
                street: myselfResult.data.readMyself[0].Company.Street || "",
                zip: myselfResult.data.readMyself[0].Company.Zip || "",
                city: myselfResult.data.readMyself[0].Company.City || "",
                canton: myselfResult.data.readMyself[0].Company.Canton || "",
                distance: myselfResult.data.readMyself[0].Company.Distance || 50,
                latitude:
                  myselfResult.data.readMyself[0].Company.Latitude || null,
                longitude:
                  myselfResult.data.readMyself[0].Company.Longitude || null,
                phone: myselfResult.data.readMyself[0].Company.Phone || "",
                fax: myselfResult.data.readMyself[0].Company.Fax || "",
                email: myselfResult.data.readMyself[0].Company.Email || "",
                website: myselfResult.data.readMyself[0].Company.Website || "",
                employeeCount:
                  myselfResult.data.readMyself[0].Company.NumberOfEmployees || "",
                legalForm:
                  myselfResult.data.readMyself[0].Company.LegalForm || "",
                foundingYear:
                  myselfResult.data.readMyself[0].Company.FoundingYear || "",
                masterOperation:
                  FormatHelper.boolToString(
                    myselfResult.data.readMyself[0].Company.MasterBusiness
                  ) || "",
                trainingCompany:
                  FormatHelper.boolToString(
                    myselfResult.data.readMyself[0].Company.TrainingCompany
                  ) || "",
                branchCategory:
                  myselfResult.data.readMyself[0].Company.BusinessActivityID ||
                  "",
                branchSubs: branchSubsData || [],
                otherBusinessActivities: otherBusinessActivities || [],
                otherBusinessSpecialities: otherBusinessSpecialties || [],
                users: companyUsersData || [],
                stripeIsTrialing:
                  myselfResult.data.readMyself[0].Company.StripeIsTrialing,
                stripeTrialingDays:
                  myselfResult.data.readMyself[0].Company.StripeTrialingDays,
                stripePlanId:
                  myselfResult.data.readMyself[0].Company.StripePlanID,
                stripeValidThrough:
                  myselfResult.data.readMyself[0].Company.StripeValidThrough,
                stripeProductId:
                  myselfResult.data.readMyself[0].Company.StripeProductID,
                stripeCancelAt:
                  myselfResult.data.readMyself[0].Company.StripeCancelAt,
                stripeFuturePlans:
                  myselfResult.data.readMyself[0].Company.StripeFuturePlans,
                trialPossible: trialPossible,
                memberships: memberships,
                allMemberships: allMemberships,
                micrositeColorScheme:
                  myselfResult.data.readMyself[0].Company.MicrositeColorScheme,
                micrositeDescription:
                  myselfResult.data.readMyself[0].Company.MicrositeDescription,
                micrositeEmailApplicationText:
                  myselfResult.data.readMyself[0].Company
                    .MicrositeEmailApplicationText,
                micrositeImages: micrositeImages,
                micrositeLogo:
                  myselfResult.data.readMyself[0].Company.MicrositeLogo,
                additionalMemberships:
                  myselfResult.data.readMyself[0].Company.AdditionalMemberships,
                micrositeReferences: micrositeReferences,
                micrositeCertified:
                  myselfResult.data.readMyself[0].Company.MicrositeCertified,
                micrositeCertifiedUntil:
                  myselfResult.data.readMyself[0].Company.MicrositeCertifiedUntil,
                hasExternalApply: hasExternalApply,
                zipSearchAreas:
                  myselfResult.data.readMyself[0].Company.ZipSearchAreas,
                zipSearchStatistic:
                  myselfResult.data.readMyself[0].Company.ZipSearchStatistic,
                constructionSums:
                  myselfResult.data.readMyself[0].Company.ConstructionSumList,
                applicationFollowUpTimeInDays:
                  myselfResult.data.readMyself[0].Company.ApplicationFollowUpTimeInDays
              },
              dossierTemplates: dossierTemplatesData,
              permissions: parsePermissions(
                myselfResult.data.readMyself[0].Permissions
              ),
              fulltextSearchRequests:
                myselfResult.data.readMyself[0].FulltextSearchRequests,
              searchAreas: myselfResult.data.readMyself[0].SearchAreas,
            });
            // Add the user to global context
            setUser(user);
            // Finish loading process (remove loading-indicator)
            setAuthorizing(false);
          } else {
            setMessage(MESSAGE_TYPES.ERROR, t("error.login"));
            setAuthorizing(false);
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myselfResult, token, user])

  /**
   * GraphQL trigger and passwordLostResult handling
   */
  useEffect(() => {
    if (!passwordLostResult.fetching) {
      if (passwordLostResult.error) {
        ExternalErrorLogger.log({
          text: "Error password lost on Login",
          data: {
            user: user.getData(),
            token: token.getData(),
            error: passwordLostResult.error,
          },
        });
        setMessage(MESSAGE_TYPES.ERROR, t("error.general"));
      } else {
        // Query not fetching right now
        if (
          typeof passwordLostResult.data !== "undefined" &&
          typeof passwordLostResult.data.passwordLost !== "undefined"
        ) {
          if (passwordLostResult.data.passwordLost.response !== "ok") {
            ExternalErrorLogger.log({
              text: "Error password lost response on Login",
              data: {
                user: user.getData(),
                token: token.getData(),
                response: passwordLostResult.data.passwordLost.response,
              },
            });
            setMessage(MESSAGE_TYPES.ERROR, t("error.general"));
          } else {
            if (!showMailSendMessage) {
              setShowMailSendMessage(true);
            }
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordLostResult])

  /**
   * handleEmailChange
   *
   * @param event
   */
  const handleEmailChange = (event) => {
    setFormValues({ ...formValues, email: event.target.value });
  };

  /**
   * handlePasswordChange
   *
   * @param event
   */
  const handlePasswordChange = (event) => {
    setFormValues({ ...formValues, password: event.target.value });
  };

  /**
   * handleClickShowPassword
   */
  const handleClickShowPassword = () => {
    setFormValues({ ...formValues, showPassword: !formValues.showPassword });
  };

  /**
   * handlePasswordForgot
   */
  const handlePasswordForgot = () => {
    setShowPasswordForgotDialog(true);
  };

  /**
   * handlePasswordForgotSubmit
   */
  const handlePasswordForgotSubmit = async () => {
    // Validate form data
    const { error } = passwordForgotSchema.validate(formValues, {
      abortEarly: false,
    });

    if (error) {
      const formErrors = FormValueErrorsBuilder(error, t);
      setFormValueErrors({ ...formErrors });
    } else {
      executePasswordLostQuery();
    }
  };

  /**
   * closeDialogue
   */
  const closeDialogue = () => {
    setShowPasswordForgotDialog(false);
  };

  /**
   * PasswordForgotDialogContent
   */
  const PasswordForgotDialogContent = (
    <>
      <Grid item className={clsx(classes.item, classes.itemHeader)}>
        <h1 className={classes.title}>{t("form.label.password.forgot")}</h1>
      </Grid>
      {showMailSendMessage ? (
        <Grid item className={classes.item}>
          <Card className={classes.card}>
            <CardContent>
              <Typography>{t("general.label.password.reset")}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ) : (
        <>
          <Grid item className={classes.item}>
            <TextField
              id="inputEmail"
              variant="outlined"
              align="left"
              className={classes.textField}
              label={t("form.label.email")}
              value={formValues.email}
              error={GetSafe(() => formValueErrors.email.hasError, false)}
              helperText={GetSafe(() => formValueErrors.email.message, "")}
              onChange={handleEmailChange}
            />
          </Grid>
          <Grid item className={classes.item}>
            <Button
              id="buttonFormSubmit"
              variant="contained"
              color="primary"
              disabled={passwordLostResult.fetching}
              className={classes.button}
              onClick={handlePasswordForgotSubmit}
            >
              {t("general.label.claim.password.change.link")}
              {passwordLostResult.fetching && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </Grid>
        </>
      )}
    </>
  );

  /**
   * LoginDialogContent
   */
  const LoginDialogContent = (
    <form onSubmit={loginUser} className={classes.form}>
      <Grid item className={clsx(classes.item, classes.itemHeader)}>
        <h1 className={classes.title}>{t("general.greeting.welcome.back")}</h1>
      </Grid>
      <Grid item className={classes.item}>
        <TextField
          id="inputEmail"
          variant="outlined"
          align="left"
          className={classes.textField}
          label={t("form.label.email")}
          value={formValues.email}
          error={GetSafe(() => formValueErrors.email.hasError, false)}
          helperText={GetSafe(() => formValueErrors.email.message, "")}
          onChange={handleEmailChange}
        />
      </Grid>
      <Grid item className={classes.item}>
        <TextField
          id="outlined-adornment-password"
          variant="outlined"
          align="left"
          className={classes.textField}
          error={GetSafe(() => formValueErrors.password.hasError, false)}
          type={formValues.showPassword ? "text" : "password"}
          label={t("form.label.password")}
          value={formValues.password}
          helperText={GetSafe(() => formValueErrors.password.message, "")}
          onChange={handlePasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  color="primary"
                  aria-label="Toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {formValues.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {GetSafe(() => formValueErrors.login.hasError, false) && (
        <Grid item className={classes.item}>
          <FormHelperText className={classes.error} id="loginErrorMessage">
            {formValueErrors.login.message}
          </FormHelperText>
        </Grid>
      )}
      <Grid item className={clsx(classes.item, classes.itemPassword)}>
        <Link
          id="buttonPasswordForgot"
          component="button"
          type="button"
          variant="body2"
          underline="always"
          onClick={handlePasswordForgot}
        >
          {t("form.label.password.forgot")}
        </Link>
      </Grid>
      <Grid item className={classes.item}>
        <Button
          id="buttonFormSubmit"
          variant="contained"
          color="primary"
          disabled={authorizing}
          className={classes.button}
          type="submit"
        >
          {t("general.label.login")}
          {authorizing && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Button>
      </Grid>
      <Grid item className={clsx(classes.item, classes.itemNoAccount)}>
        <div>
          <span className={classes.notice}>
            {t("form.label.hint.no.account")}
          </span>
          <br />
          <Link
            variant="body2"
            underline="always"
            target="_blank"
            href={getAbsoluteLink(
              cmsLinks,
              "CmsMenu",
              externalLinkTitles.PRICES
            )}
          >
            {t("form.label.register.now")}
          </Link>
        </div>
      </Grid>
    </form>
  );

  /**
   * return
   */
  return (
    <Dialog fullWidth maxWidth="md" open>
      <DialogTitle id="dialog-title" className={classes.dialogTitle}>
        {showPasswordForgotDialog && (
          <IconButton
            onClick={() => {
              closeDialogue();
            }}
          >
            <CloseIcon id="closeDialog" />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Grid container direction="column" justify="center" alignItems="center">
          {showPasswordForgotDialog
            ? PasswordForgotDialogContent
            : LoginDialogContent}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Login;
